<template>
<div>
  <div class="box jz">
    <div class="banner" >
      <div v-if="bannerList.length>0">
        <img :src="bannerList[0].bannerImg" alt="" @click="topage(item.h5Url)">
      </div>
      <div class="img"></div>
    </div>
    <div class="label">
      <div class="tdoc">
        <span class="qy">
          <img src="~assets/doc.svg" alt="">
        </span>
      </div>
      <div class="choosed">
        <div class="group">
          <span>选择科室:</span>
          <div class="ks">
            <div v-for="(item, index) in keshi" :key="index" class="kk" :class="{actived: index === currentIndex}" @click="itemClick(index)" style="cursor: pointer;">{{item}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <!-- <doctors-list :doctors="doctors" :type="'doctor'"></doctors-list> -->
      <el-row>
          <el-col :span="25" v-for="project in doctors" :key="project.pid" :offset="1">
              <doctors-list-itemtest :doctors-item="project" class="now"></doctors-list-itemtest>
          </el-col>
      </el-row>
      <div class="block">
      <!-- :hide-on-single-page="value" 只有一页的时候隐藏 -->
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="100"
        :current-page="currentPage"
        layout="prev, pager, next, jumper"
        :total="1000">
    </el-pagination> -->
    </div>
    </div>
  </div>
  <bottom-bar class="bottom"></bottom-bar>
  </div>
</template>

<script>
// import DoctorsList from 'components/content/doctors/DoctorsList'
import DoctorsListItemtest from 'components/content/doctors/DoctorsListItemtest'
import { getDoctorInfoList, getBanner } from 'network/getData'
import BottomBar from 'components/content/bottombar/BottomBar'
export default {
  name: 'Doctor',
  components: {
    // DoctorsList,
    BottomBar,
    DoctorsListItemtest
  },
  data () {
    return {
      currentPage: 1,
      keshi: [
        '内科',
        '外科',
        '妇产科',
        '儿科',
        '男科',
        '皮肤性病科',
        '五官科',
        '肿瘤科',
        '精神心理科',
        '不孕不育',
        '其他'
      ],
      keshichange: [
        'nk',
        'wk',
        'fck',
        'ek',
        'nank',
        'pfxbk',
        'wgk',
        'zlk',
        'jsxlk',
        'byby',
        'remain'
      ],
      doctors: [
      ],
      bannerList: [],
      total: 150,
      currentIndex: 0,
      value: false
    }
  },
  methods: {
    async getDoctorInfoListdata (filterCondition, row, page, type) {
      try {
        const result = await getDoctorInfoList(filterCondition, row, page, type)
        this.doctors = result.data.data
      } catch (error) {
        // console.log(error)
      }
    },
    handleClick (tab, event) {
      // console.log(tab, event)
    },
    itemClick (index) {
      this.currentIndex = index
      this.currentPage = 1
      this.getDoctorInfoListdata(this.keshichange[index], 15, 1, 'doctor')
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.currentPage = val
      this.getDoctorInfoListdata(this.keshichange[this.currentIndex], 15, this.currentPage, 'doctor')
    },
    async getBannerList (type) {
      try {
        const result = await getBanner(type)
        this.bannerList = result.data.data
        // console.log('this.bannerList', this.bannerList)
      } catch (error) {
        // console.log(error)
      }
    },
    topage (e) {
      if (e !== 'empty') {
        window.open(e)
      }
    }
  },
  // metaInfo () {
  //   return {
  //     meta: [
  //       { name: 'keywords', content: '科普视频,专家文章,专家音频,名医问答,查疾病,瑞尔安心、瑞尔良医、瑞尔良医app、瑞尔安心官网、瑞尔视景、瑞尔视景科技有限公司、单道动态心电记录仪、7天可穿戴心电监测设备、智能心电监测设备' },
  //       { name: 'description', content: '瑞尔安心在线平台,专家,疾病,科室等详细信息,由专家生产的科普视频,文章,音频等内容,瑞尔安心平台向全民提供“科普视频+语音问答+图文咨询+文章+大数据分析”多位一体的精准健康科普知识，满足大众多样化、多场景应用的健康科普需求。致力于通过互联网技术推动医生共享、知识共享、服务共享、经济共享，放大医生价值和健康科普内容价值，用通俗易懂的方式以及直白语言，制作出大众听得懂、记得住、学得会、用得上的健康科普知识，切实提升公众健康素养，让更多人能够健康地享受美好生活，助力全民健康。研发出多款医疗器械，实现了心脑血管病患者、高危人群得日常疾病筛查和健康管理，并配套了全程专业的健康管理服务，在国内医疗产品行业中具有很高的地位和影响。公司主要从事公司下设医学研发中心、软件开发中心、生产制造中心、营销中心、医学服务中心。' }
  //     ]
  //   }
  // },
  mounted () {
    this.total = this.doctors.length
    this.getBannerList('doctor')
    this.getDoctorInfoListdata('nk', 15, 1, 'doctor')
  }
}
</script>

<style scoped>
.box{
    width: 1440px;
    /* background: #FAFAFA; */
}
.banner{
    /* position: absolute; */
    width: 1440px;
    height: 200px;
    /* left: 120px; */
    /* background: #E8E8E8; */
    /* background-image: url("~assets/banner.png"); */
}
.banner img{
  /* position: absolute; */
  width: 1440px;
  height: 200px;
  /* left: -39px;
  top: -5px; */
  /* background-image: url("~assets/banner.png"); */
}
.label{
  width: 1200px;
  height: 148px;
  margin: 0 auto;
  background: #FFFFFF;
}
.tdoc{
  width: 144px;
  height: 28px;
  font-size: 36px;
  line-height: 28px;
  text-align: center;
  padding: 30px;
  color: #262626;
}
.choosed{
  height: 60px;
}
.group{
  /* position: absolute; */
  display: flex;
  height: 24px;
  /* left: 6.67%;
  right: 32.08%;
  top: 18px; */
  color: #999999;
}
.actived{
  background: #F6A609;
  color: #FFFFFF;
  /* width: 28px; */
  padding: 0 5px;
  height: 24px;
  border-radius: 4px;
}
.group span{
  margin-left: 33px;
  height: 24px;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
}
.kk{
  float: left;
  margin-left: 18px;
}
.ks{
  left: 50px;
  margin-left: 18px;
}
.container{
  width: 1200px;
  margin: 0 auto;
  margin-top: -30px;
}
.block{
  margin: 50px 0px;
  width: 1200px;
  height: 60px;
}
.bottom{
  width: 100%;
  /* height: 594px; */
  background: #FFFFFF;
}
.el-col-25{
  width: 18%;
}
.el-col-offset-1{
  margin-left: 0%;
  margin-right: 2%;
  margin-top: 3%;
}
.el-pagination{
  /* margin: 0 auto; */
  text-align: center;
}
/* @font-face{
  font-family: 'pang';
  src : url('~assets/font/pang.ttf');
} */
.qy{
  font-family: 'PangMenZhengDao-3';
}
</style>
